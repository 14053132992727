/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import '@blocknote/core/style.css'
import '@blocknote/mantine/style.css'

import type { BlockNoteEditor, PartialBlock } from '@blocknote/core'
import { BlockNoteSchema, defaultBlockSpecs, filterSuggestionItems, insertOrUpdateBlock } from '@blocknote/core'
import { BlockNoteView } from '@blocknote/mantine'
import type { DefaultReactSuggestionItem } from '@blocknote/react'
import {
  BlockColorsItem,
  DragHandleMenu,
  getDefaultReactSlashMenuItems,
  RemoveBlockItem,
  SideMenu,
  SideMenuController,
  SuggestionMenuController,
  useCreateBlockNote
} from '@blocknote/react'
import React, { useEffect, useRef, useState } from 'react'
import { BsPalette } from 'react-icons/bs'
import { FaCode } from 'react-icons/fa6'
import { IoTrashOutline } from 'react-icons/io5'

import TurnInto from '../partials/custom_side_menu/TurnInto'
import { CodeBlock } from './CodeBlock'

type BlockquoteEditorProps = {
  onContentChange: (content: string) => void
  initialContent?: string
  editable?: boolean
}

export const schema = BlockNoteSchema.create({
  blockSpecs: {
    ...defaultBlockSpecs,
    code_block: CodeBlock
  }
})

const insertCodeBlock = (editor: typeof schema.BlockNoteEditor) => ({
  title: 'Code Block',
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      type: 'code_block',
      content: ''
    })
  },
  group: 'Basic',
  icon: <FaCode />
})

const removeMediaItems = (items: DefaultReactSuggestionItem[]) => {
  return items.filter((item) => item.group !== 'Media')
}

const insertHelloWorldItem = (editor: BlockNoteEditor) => ({
  title: 'Insert Hello World',
  onItemClick: () => {
    const currentBlock = editor.getTextCursorPosition().block

    const helloWorldBlock: PartialBlock = {
      type: 'paragraph',
      content: [{ type: 'text', text: '', styles: { bold: true } }]
    }

    editor.insertBlocks([helloWorldBlock], currentBlock, 'before')
  },
  aliases: ['code'],
  group: 'Other',
  subtext: "Used to insert a block with 'Hello World' below."
})

const getCustomSlashMenuItems = (editor: any): DefaultReactSuggestionItem[] => {
  const defaultItems = getDefaultReactSlashMenuItems(editor)
  const filteredItems = removeMediaItems(defaultItems)

  return [...filteredItems, insertCodeBlock(editor)]
}

const Editor = ({ onContentChange, editable, initialContent }: BlockquoteEditorProps) => {
  const editorRef = useRef<HTMLDivElement | null>(null)
  const [lastUpdatedContent, setLastUpdatedContent] = useState(initialContent || '')
  const editor = useCreateBlockNote({
    schema,
    initialContent: initialContent ? (JSON.parse(initialContent) as PartialBlock[]) : undefined
  })

  const handleClickOutside = (event: MouseEvent) => {
    if (editorRef.current && !editorRef.current.contains(event.target as Node)) {
      const currentContent = JSON.stringify(editor.document, null, 2)
      if (currentContent !== lastUpdatedContent) {
        onContentChange(currentContent)
        setLastUpdatedContent(currentContent)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [lastUpdatedContent, editor.document])

  return (
    <div ref={editorRef}>
      <BlockNoteView editable={editable} editor={editor} theme='light' sideMenu={false} slashMenu={false}>
        <SuggestionMenuController triggerCharacter='/' getItems={async (query) => filterSuggestionItems(getCustomSlashMenuItems(editor), query)} />
        <SideMenuController
          sideMenu={(props) => (
            <SideMenu
              {...props}
              dragHandleMenu={() => (
                <DragHandleMenu {...props}>
                  <TurnInto {...props} />

                  <RemoveBlockItem {...props}>
                    <div className='flex items-center gap-2 hover:text-red-500'>
                      <IoTrashOutline size={16} />
                      Delete
                    </div>
                  </RemoveBlockItem>

                  <BlockColorsItem {...props}>
                    <div className='flex items-center gap-2'>
                      <BsPalette size={16} />
                      Change colors
                    </div>
                  </BlockColorsItem>
                </DragHandleMenu>
              )}
            />
          )}
        />
      </BlockNoteView>
    </div>
  )
}

export default Editor
