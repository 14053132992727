import { Input } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import type { NoteItem } from '../../common/type'
import { updateNote } from '../../reducers/tnoteReducer'

const { TextArea } = Input

type NoteTitleProps = {
  note: NoteItem
}

const NoteTitle = ({ note }: NoteTitleProps) => {
  const dispatch = useDispatch<any>()
  const inputRef = useRef<any>(null)

  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState(note.title)
  const [lastUpdatedTitle, setLastUpdatedTitle] = useState(note.title)

  const updateNoteTitle = async (value: string) => {
    try {
      await dispatch(
        updateNote({
          noteId: note.id,
          title: value
        })
      )
      setLastUpdatedTitle(value)
    } catch (error) {}
  }

  const onInputChange = (value: string) => {
    setTitle(value)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (title.trim() === '') {
        setTitle(lastUpdatedTitle)
      } else {
        updateNoteTitle(title)
      }
      setIsEditing(false)
    }
  }

  const handleClickOutside = () => {
    if (inputRef.current) {
      if (isEditing) {
        if (title.trim() === '') {
          setTitle(lastUpdatedTitle)
        } else if (title !== lastUpdatedTitle) {
          updateNoteTitle(title)
        }
        setIsEditing(false)
      }
    }
  }

  useEffect(() => {
    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isEditing, title])

  return (
    <div className='mx-[54px] pb-5 pt-10'>
      <TextArea
        ref={inputRef}
        spellCheck='false'
        onBlur={() => setIsEditing(false)}
        onFocus={() => setIsEditing(true)}
        onKeyDown={onKeyDown}
        value={title}
        onChange={(e) => onInputChange(e.target.value)}
        autoSize
        className='custom-textarea rounded-[2px] border-none p-0 text-[40px] font-bold leading-[48px] text-[#37352f]'
      />
    </div>
  )
}

export default NoteTitle
