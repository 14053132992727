/* eslint-disable react-hooks/rules-of-hooks */
import { createReactBlockSpec } from '@blocknote/react'
import React from 'react'

export const CodeBlock = createReactBlockSpec(
  {
    type: 'code_block',
    propSchema: {
      language: { default: 'javascript' }
    },
    content: 'inline'
  },
  {
    render: (props) => {
      return (
        <div className='w-full rounded-sm bg-[#faf6ea] px-8'>
          <pre>
            <code ref={props.contentRef} />
          </pre>
        </div>
      )
    }
  }
)

/* eslint-disable react-hooks/rules-of-hooks */
// import 'prismjs/themes/prism-tomorrow.css'

// import { createReactBlockSpec } from '@blocknote/react'
// import Prism from 'prismjs'
// import React, { useEffect, useRef } from 'react'

// export const CodeBlock = createReactBlockSpec(
//   {
//     type: 'code_block',
//     propSchema: {
//       language: { default: 'javascript' }
//     },
//     content: 'inline'
//   },
//   {
//     render: (props) => {
//       const localRef = useRef<any>(null)
//       useEffect(() => {
//         Prism.highlightElement(localRef.current)
//       }, [props.block.content, props.block.props.language])
//       return (
//         <div className='w-full rounded-sm bg-[#faf6ea] px-8'>
//           <pre>
//             <code
//               ref={(node) => {
//                 props.contentRef(node) // Provide the node to BlockNote's contentRef
//                 localRef.current = node // Assign the node to the localRef
//               }}
//               className={`language-${props.block.props.language}`}
//               contentEditable
//               suppressContentEditableWarning
//             />
//           </pre>
//         </div>
//       )
//     }
//   }
// )
