/* eslint-disable @typescript-eslint/no-unused-vars */
import { reArrangeArray } from '@shared/common/utils'
import BaseDraggable from '@shared/components/BaseDraggable'
import { fetchNotes, updateNotePosition } from '@shared/modules/tnote/reducers/tnoteReducer'
import type { RootState } from '@shared/store'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'

import Item from './Item'

const NoteList: React.FC = () => {
  const dispatch = useDispatch<any>()
  const refItems = useRef<any>()
  const currentNoteId = useParams().noteId
  const navigate: NavigateFunction = useNavigate()
  const filter = useSelector((state: RootState) => state.tnote.note.filter)
  const items = useSelector((state: RootState) => state.tnote.note.items)
  const [expanded, setExpanded] = useState<any>({})

  const fetchData = async () => {
    try {
      await dispatch(fetchNotes())
    } catch (error) {}
  }

  const onEndDragNote = async (event: any) => {
    const { newIndex, oldIndex } = event

    if (oldIndex === newIndex) {
      return
    }

    const newArr = reArrangeArray(refItems.current, oldIndex, newIndex)

    const positions = newArr.map((item, index) => ({
      id: item.id,
      position: index
    }))

    try {
      await dispatch(updateNotePosition({ positions }))
    } catch (error) {}
  }

  const handleItemClick = (id: string) => {
    if (currentNoteId !== id) {
      navigate(`/tnote/${id}`)
    }
  }

  const onExpand = (id: string) => {
    setExpanded((prev: any) => ({
      ...prev,
      [id]: !prev[id]
    }))
  }

  useEffect(() => {
    fetchData()
  }, [JSON.stringify(filter)])

  useEffect(() => {
    refItems.current = [...items]
  }, [items])

  return (
    <div className='flex-1 overflow-y-auto'>
      <BaseDraggable className='flex flex-col gap-[1px]' group='group-note' dragClass='.note_item' onEnd={onEndDragNote}>
        {items?.map((item: any) => (
          <Item
            key={item.id}
            data={item}
            onClick={() => handleItemClick(item.id)}
            onExpand={() => onExpand(item.id)}
            className='note_item'
            active={currentNoteId === item.id}
          />
        ))}
      </BaseDraggable>
    </div>
  )
}

export default NoteList
