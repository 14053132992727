import type { DragHandleMenuProps } from '@blocknote/react'
import { useBlockNoteEditor, useComponentsContext } from '@blocknote/react'
import React from 'react'

const Heading2 = (props: DragHandleMenuProps) => {
  const editor = useBlockNoteEditor()
  const Components = useComponentsContext()!

  return (
    <Components.Generic.Menu.Item
      checked={props.block.type === 'heading' && props.block.props.level === 2}
      onClick={() => {
        editor.updateBlock(props.block, { type: 'heading', props: { level: 2 } })
      }}
    >
      Heading 2
    </Components.Generic.Menu.Item>
  )
}

export default Heading2
