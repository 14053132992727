/* eslint-disable @typescript-eslint/no-unused-vars */
import type { DragHandleMenuProps } from '@blocknote/react'
import { useBlockNoteEditor, useComponentsContext } from '@blocknote/react'
import React from 'react'
import { BsRepeat } from 'react-icons/bs'
import { IoAccessibilityOutline } from 'react-icons/io5'

import Heading1 from './Heading1'
import Heading2 from './Heading2'
import Heading3 from './Heading3'
import NormalText from './NormalText'

const TurnInto = (props: DragHandleMenuProps) => {
  const editor = useBlockNoteEditor()
  const Components = useComponentsContext()!
  const Block = Components.Generic.Menu

  return (
    <Block.Root sub position='right'>
      <Block.Trigger>
        <Block.Item subTrigger icon={<IoAccessibilityOutline />}>
          <div className='flex min-w-[200px] items-center gap-2'>
            <BsRepeat size={16} />
            Turn Into
          </div>
        </Block.Item>
      </Block.Trigger>
      <Block.Dropdown>
        <NormalText {...props} />
        <Heading1 {...props} />
        <Heading2 {...props} />
        <Heading3 {...props} />
      </Block.Dropdown>
    </Block.Root>
  )
}

export default TurnInto
