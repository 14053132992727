import type { DragHandleMenuProps } from '@blocknote/react'
import { useBlockNoteEditor, useComponentsContext } from '@blocknote/react'
import React from 'react'

const NormalText = (props: DragHandleMenuProps) => {
  const editor = useBlockNoteEditor()
  const Components = useComponentsContext()!

  return (
    <Components.Generic.Menu.Item
      checked={props.block.type === 'paragraph'}
      onClick={() => {
        editor.updateBlock(props.block, { type: 'paragraph' })
      }}
    >
      Text
    </Components.Generic.Menu.Item>
  )
}

export default NormalText
